import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL, APP_GUID, AUTH_TYPE, AUTH_ID } from '../../app.constants';

@Injectable()
export class AuthServerProvider {
  private CURRENT_PROJECT_HEADER = 'idcurrentproject';
  private CURRENT_USER_PROJECT_HEADER = 'iduserproject';

  constructor(
    private $localStorage: LocalStorageService,
    private http: HttpClient
    ) { }

  login(credentials): Observable<any> {
    if (AUTH_TYPE === 0) {
      const data = {
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe
      };
      return this.http.post(`${API_URL}api/authenticate`, data, {observe: 'response'}).pipe(map((res:any) => {
        const bearerToken = res.headers.get('Authorization');
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            const jwt = bearerToken.slice(7, bearerToken.length);
            this.storeAuthenticationToken(jwt, credentials.rememberMe);
            return jwt;
        }
      }));

    } else {
      const data = {
        username: credentials.username,
        password: credentials.password,
        rememberMe: credentials.rememberMe,
        app: APP_GUID,
        authid: AUTH_ID
      };
      return this.http.post(`${API_URL}api/authenticatefromapp`, data, {observe: 'response'}).pipe(map((res:any) => {
        const bearerToken = res.headers.get('Authorization');
        console.log(bearerToken)
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            const jwt = bearerToken.slice(7, bearerToken.length);
            this.storeAuthenticationToken(jwt, credentials.rememberMe);
            return jwt;
        }
      }));
    }
  }

  storeAuthenticationToken(jwt, rememberMe) {
    this.$localStorage.store('authenticationToken', jwt);
  }

  getToken() {
    return this.$localStorage.retrieve('authenticationToken');
  }

  logout(): Observable<any> {
    return new Observable((observer) => {
        this.$localStorage.clear('authenticationToken');
        this.$localStorage.clear('currentUser');
        observer.complete();
    });
  }

  getCurrentUser(): any {
    // FIXME ajout de principal dans AuthServerProvider creer un bug -> association bi-directionnel impossible ?
    return this.$localStorage.retrieve('currentUser');
  }

  getOptions(): HttpHeaders {
    const headers = new HttpHeaders();
    const authToken = this.getToken();
    let options = headers.append('Authorization', 'Bearer ' + authToken);
    const currentUser = this.getCurrentUser();
    if (currentUser !== null && currentUser !== undefined) {
      options = options.append(this.CURRENT_PROJECT_HEADER, currentUser.idproject);
      options = options.append(this.CURRENT_USER_PROJECT_HEADER, currentUser.id);
    }
    return options;
  }

}
