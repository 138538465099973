export enum TypeChampEtatEnum {

    PAGE,
    BLOC,
    LIBELLE,
    CALCUL,
    IMAGE,
    PREDEFINI,
    VARIABLE,
    CHECKBOX,
    ETAT_INTERNE
}
