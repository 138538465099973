import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EventChargementComponent } from './dialog/chargement/event-chargement.component';
import { EventInfoComponent } from './dialog/event-info.component';
import { EventOuiNonComponent } from './dialog/event-ouinon.component';
import { EventSaisieComponent } from './dialog/event-saisie.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [  
    EventChargementComponent,
    EventInfoComponent,
    EventOuiNonComponent,
    EventSaisieComponent
  ],
  exports: [
    EventChargementComponent,
    EventInfoComponent,
    EventOuiNonComponent,
    EventSaisieComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedCompModule { }
