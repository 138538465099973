import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  constructor() { }

  isDate(date: any): boolean {
    const dateCheck = new Date(date);
    return Object.prototype.toString.call(dateCheck) === '[object Date]';
  }

  DateToString(date: any): string {
    let mois = date.month;
    let jour = date.day;

    if (date.month < 10) {
      mois = '0' + date.month;
    }

    if (date.day < 10) {
      jour = '0' + date.day;
    }

    return `${date.year}-${mois}-${jour}`;
  }

  CurrentDate(): string {
    const dd = new Date();
    let jj: string;
    let mm: string;
    jj = String(dd.getDate());
    if (dd.getDate() < 10) {
      jj = '0' + dd.getDate();
    }
    mm = String(dd.getMonth() + 1);
    if (dd.getMonth() + 1 < 10) {
      mm = '0' + (dd.getMonth() + 1);
    }

    return `${jj}/${mm}/${dd.getFullYear()}`;
  }

  monthDiff(date1, date2): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  jourenlettre(dd): string {
    const day = dd.getDay();
    if (day === 0) {
      return 'Dimanche';
    } else if (day === 1) {
      return 'Lundi';
    } else if (day === 2) {
      return 'Mardi';
    } else if (day === 3) {
      return 'Mercredi';
    } else if (day === 4) {
      return 'Jeudi';
    } else if (day === 5) {
      return 'Vendredi';
    } else if (day === 6) {
      return 'Samedi';
    }
  }

  moisenlettre(nummois: number): string {
    let mois = '';
    if (nummois === 1) {
      mois = 'janvier';
    } else if (nummois === 2) {
      mois = 'février';
    } else if (nummois === 3) {
      mois = 'mars';
    } else if (nummois === 4) {
      mois = 'avril';
    } else if (nummois === 5) {
      mois = 'mai';
    } else if (nummois === 6) {
      mois = 'juin';
    } else if (nummois === 7) {
      mois = 'juillet';
    } else if (nummois === 8) {
      mois = 'août';
    } else if (nummois === 9) {
      mois = 'septembre';
    } else if (nummois === 10) {
      mois = 'octobre';
    } else if (nummois === 11) {
      mois = 'novembre';
    } else if (nummois === 12) {
      mois = 'décembre';
    }
    return mois;
  }

  getMonth(date1): string {
    date1 = new Date(date1);
    let nummois = date1.getMonth() + 1;
    let mois = '';
    if (nummois === 1) {
      mois = 'janvier';
    } else if (nummois === 2) {
      mois = 'février';
    } else if (nummois === 3) {
      mois = 'mars';
    } else if (nummois === 4) {
      mois = 'avril';
    } else if (nummois === 5) {
      mois = 'mai';
    } else if (nummois === 6) {
      mois = 'juin';
    } else if (nummois === 7) {
      mois = 'juillet';
    } else if (nummois === 8) {
      mois = 'août';
    } else if (nummois === 9) {
      mois = 'septembre';
    } else if (nummois === 10) {
      mois = 'octobre';
    } else if (nummois === 11) {
      mois = 'novembre';
    } else if (nummois === 12) {
      mois = 'décembre';
    }
    return mois;
  }

  getYear(date1) {
    return new Date(date1).getFullYear();
  }

  getDate(date1) {
    return new Date(date1).getDate();
  }

  getWeekDay(dd) {
    dd = new Date(dd);
    const day = dd.getDay();
    if (day === 0) {
      return 'dimanche';
    } else if (day === 1) {
      return 'lundi';
    } else if (day === 2) {
      return 'mardi';
    } else if (day === 3) {
      return 'mercredi';
    } else if (day === 4) {
      return 'jeudi';
    } else if (day === 5) {
      return 'vendredi';
    } else if (day === 6) {
      return 'samedi';
    }
  }

  DateToUs(date1): string {
    date1 = new Date(date1);
    if (date1 === 'Invalid Date' || date1 === '' || date1 === null || date1 === undefined) { return ''; }
    const dd = new Date(date1);
    let mm: string = String(dd.getMonth() + 1);
    let jj: string = String(dd.getDate());

    if (dd.getMonth() + 1 < 10 ) {
      mm = `0${(dd.getMonth() + 1)}`;
    }

    if (dd.getDate() < 10) {
      jj = `0${dd.getDate()}`;
    }

    return `${String(dd.getFullYear())}-${mm}-${jj}`;
  }

  DateToFr(date1): string {
    if (date1 === 'Invalid Date' || date1 === '' || date1 === null || date1 === undefined) { return ''; }
    const dd = new Date(date1);
    let mm: string = String(dd.getMonth() + 1);
    let jj: string = String(dd.getDate());

    if (dd.getMonth() + 1 < 10 ) {
      mm = '0' + (dd.getMonth() + 1);
    }

    if (dd.getDate() < 10) {
      jj = '0' + dd.getDate();
    }

    return String(`${jj}/${mm}/${dd.getFullYear()}`);
  }

  DateHeureToUs(date1, showSeconds = false): string {
    if (date1 === 'Invalid Date' || date1 === '' || date1 === null || date1 === undefined) { return ''; }
    const dd = new Date(date1);
    let mm: string = String(dd.getMonth() + 1);
    let jj: string = String(dd.getDate());
    let hh: string = String(dd.getHours());
    let mi: string = String(dd.getMinutes());
    let ss: string = String(dd.getSeconds());

    if (dd.getMonth() + 1 < 10 ) {
      mm = '0' + (dd.getMonth() + 1);
    }
    if (dd.getDate() < 10) {
      jj = '0' + dd.getDate();
    }
    if (dd.getHours() < 10) {
      hh = '0' + dd.getHours();
    }
    if (dd.getMinutes() < 10) {
      mi = '0' + dd.getMinutes();
    }
    if (dd.getSeconds() < 10) {
      ss = '0' + dd.getSeconds();
    }
    return `${String(dd.getFullYear())}-${mm}-${jj} ${hh}:${mi}${(showSeconds ? (':' + ss) : '')}`;
  }

  DateHeureToFr(date1, showSeconds = false): string {
    if (date1 === 'Invalid Date' || date1 === '' || date1 === null || date1 === undefined) { return ''; }
    const dd = new Date(date1);
    let mm: string = String(dd.getMonth() + 1);
    let jj: string = String(dd.getDate());
    let hh: string = String(dd.getHours());
    let mi: string = String(dd.getMinutes());
    let ss: string = String(dd.getSeconds());

    if (dd.getMonth() + 1 < 10 ) {
      mm = '0' + (dd.getMonth() + 1);
    }
    if (dd.getDate() < 10) {
      jj = '0' + dd.getDate();
    }
    if (dd.getHours() < 10) {
      hh = '0' + dd.getHours();
    }
    if (dd.getMinutes() < 10) {
      mi = '0' + dd.getMinutes();
    }
    if (dd.getSeconds() < 10) {
      ss = '0' + dd.getSeconds();
    }

    return String(`${jj}/${mm}/${dd.getFullYear()} ${hh}:${mi}${showSeconds ? (':' + ss) : ''}`);
  }

  UsToFr(date: string): string {
    if (date === '' || date === null || date === undefined) { return ''; }
    const year: number = +date.substring(0, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);
    return `${day}/${month}/${year}`;
  }

  FrToUs(date: string): string {
    if (date === '' || date === null || date === undefined) { return ''; }
    const year = date.substring(6, 10);
    const month = date.substring(3, 5);
    const day = date.substring(0, 2);
    return `${year}-${month}-${day}`;
  }

  UsToDate(date: string): Date {
    if (date === '' || date === null || date === undefined) { return new Date(); }

    const year: number = +date.substring(0, 4);
    let month = date.substring(5, 7);
    if (month.substring(0, 1) === '0') {
      month = month.substring(1, 2);
    }
    let day = date.substring(8, 10);
    if (day.substring(0, 1) === '0') {
      day = day.substring(1, 2);
    }
    return new Date(year, +month - 1, +day);
  }

  FrToDate(date: string): Date {
    if (date === '' || date === null || date === undefined) { return new Date(); }

    const year: number = +date.substring(6, 10);
    let month = date.substring(3, 5);
    if (month.substring(0, 1) === '0') {
      month = month.substring(1, 2);
    }
    let day = date.substring(0, 2);
    if (day.substring(0, 1) === '0') {
      day = day.substring(1, 2);
    }
    return new Date(year, +month - 1, +day);
  }

  getWeekNumber(d: Date): number {
    d = new Date(d);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil(( ( (d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  }

  getWeekNumberAndYear(d): any[] {
    d = new Date(d);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(( ( (d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    return [d.getUTCFullYear(), weekNo];
}

  dateverschaine(date1): string {
    if (date1 === '' || date1 === null || date1 === undefined) { return ''; }

    const dd = new Date(date1);
    let mm: string = String(dd.getMonth() + 1);
    let jj: string = String(dd.getDate());

    if (dd.getMonth() + 1 < 10 ) {
      mm = '0' + (dd.getMonth() + 1);
    }

    if (dd.getDate() < 10) {
      jj = '0' + dd.getDate();
    }

    return `${String(dd.getFullYear())}-${mm}-${jj}`;
  }

  heureverschaine(heure): string {
    if (heure === '' || heure === null || heure === undefined) { return ''; }

    const dd = new Date(heure);
    let hh;
    if (dd.getHours() < 10) {

      hh = '0' + dd.getHours();
    } else {
      hh = dd.getHours();
    }
    let min;
    if (dd.getMinutes() < 10) {
      min = '0' + dd.getMinutes();
    } else {
      min = dd.getMinutes();
    }
    let sec;
    if (dd.getSeconds() < 10) {
      sec = '0' + dd.getSeconds();
    } else {
      sec = dd.getSeconds();
    }
    return `${hh}:${min}:${sec}`;
  }

  getDateDifference(datedebut, datefin, rest = 0): string {
    const time = (new Date(datefin).getTime() - new Date(datedebut).getTime()) / 1000 - rest;
    let sec = time;
    const heure = time / 3600;
    let min = time / 60;
    let hh = '00';
    let mm = '00';
    let ss = '00';
    if (heure >= 1) {
      hh = Math.floor(heure).toString();
      if (heure < 10) {
        hh = '0' + hh;
      }
      min = (heure - Math.floor(heure)) * 60;
    }

    if (min >= 1) {
      mm = Math.floor(min).toString();
      if (min < 10) {
        mm = '0' + mm;
      }
      sec = (min - Math.floor(min)) * 60;
    }

    if (sec >= 1) {
      ss = Math.floor(sec).toString();
      if (sec < 10) {
        ss = '0' + ss;
      }
    }

    return `${hh}:${mm}:${ss}`;
  }

  timeStringtoTime(time: string): number {
    const date = new Date('2017-02-01 ' + time);
    const heure = date.getHours();
    const minute = date.getMinutes();
    const seconde = date.getSeconds();

    return Math.round((heure * 60 + minute + seconde / 60) * 1000) / 1000;
  }

  incDate(dateSrc, type: number, inc: number): string {
    const date = new Date(dateSrc);
    if (type === 0) {
      date.setDate(date.getDate() + inc);
    } else if (type === 1) {
      date.setMonth(date.getMonth() + inc);
    } else {
      date.setFullYear(date.getFullYear() + inc);
    }

    return this.DateToUs(date);
  }

  incHeure(heureSrc, type, inc: number): string {
    const date = new Date('2017-01-01 ' + heureSrc);
    if (type === 0) {
      date.setSeconds(date.getSeconds() + inc);
    } else if (type === 1) {
      date.setMinutes(date.getMinutes() + inc);
    } else {
      date.setHours(date.getHours() + inc);
    }
    return this.heureverschaine(date);
  }

  diffheure(heuredebut, heurefin): number {
    let hhdeb;
    if (heuredebut.substring(0, 1) === 0) {
      hhdeb = heuredebut.substring(1, 2);
    } else {
      hhdeb = heuredebut.substring(0, 2);
    }
    if (heuredebut.substring(3, 5) === 30) {
      hhdeb = Number(hhdeb) + 0.5;
    }

    let hhfin;
    if (heurefin.substring(0, 1) === 0) {
      hhfin = heurefin.substring(1, 2);
    } else {
      hhfin = heurefin.substring(0, 2);
    }
    if (heurefin.substring(3, 5) === 30) {
      hhfin = Number(hhfin) + 0.5;
    }

    return hhfin - hhdeb;
  }

  nbminute(debut, fin): number {
    const ddebut = new Date(debut);
    const dfin = new Date(fin);
    let i = 0;
    while (ddebut <= dfin) {
      i++;
      ddebut.setTime(ddebut.getTime() + 60000);
    }
    i--;
    return i;
  }

  getCurrentDateTime(): string {
    const today = new Date();
    return `${this.dateverschaine(today)} ${this.heureverschaine(today)}`;
  }

  getCurrentDate(): string {
    const today = new Date();
    return this.dateverschaine(today);
  }

  getCurrentTime(): string {
    const today = new Date();
    return this.heureverschaine(today);
  }

  getFirstDayOfMonth(d): string {
    const date = new Date(d), y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    return this.dateverschaine(firstDay);
  }

  getLastDayOfMonth(d): string {
    const date = new Date(d), y = date.getFullYear(), m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);
    return this.dateverschaine(lastDay);
  }

  getFirstDayOfWeek(d): string {
    const date = new Date(d);
    if (date.getDay() === 0) {
      date.setDate(date.getDate() - 6);
    } else {
      date.setDate(date.getDate() - (date.getDay() - 1));
    }
    return this.dateverschaine(date);
  }

  getLastDayOfWeek(d): string {
    const date = new Date(d);
    if (date.getDay() !== 0) {
      date.setDate(date.getDate() + (7 - date.getDay()));
    }
    return this.dateverschaine(date);
  }

  checkDateEquality(d1, d2): any {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

}
