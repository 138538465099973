
import { NgModule } from '@angular/core';

import { CalDndDirective } from './directives/cal-dnd.directive'

@NgModule({
  declarations: [  
    CalDndDirective
  ],
  exports: [
    CalDndDirective
  ]
})
export class UtilModule { }
