import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL, APP_GUID, AUTH_TYPE, AUTH_ID } from '../../app.constants';
import { AuthServerProvider } from './auth-jwt.service';

@Injectable()
export class AccountService  {

  private resourceUrl = `${API_URL}api/account`;
  private resourceProfileUrl = `${API_URL}api/register/profile`;

  constructor(private http: HttpClient, private authserv: AuthServerProvider) { }

  get(): Observable<any> {
    const options = this.authserv.getOptions();
    if (AUTH_TYPE === 0) {
      return this.http.get(this.resourceUrl, { headers: options });
    } else {
      return this.http.get(`${API_URL}api/accountfromapp/${APP_GUID}/${AUTH_ID}`, { headers: options });
    }
  }

  save(account: any): Observable<any> {
    const options = this.authserv.getOptions();
    return this.http.post(this.resourceUrl, account, { headers: options, responseType: 'text' });
  }

  saveProfile(profile: any): Observable<any> {
    const options = this.authserv.getOptions();
    return this.http.post(this.resourceProfileUrl, profile, { headers: options });
  }
}
