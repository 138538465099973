import { Injectable } from '@angular/core';

@Injectable()
export class CalendrierService {

  public selectedDateHeure = { date: null, heure: null};
  public selectedTache = null;
  public dblSelectedTache = null;
  public selectedEvent: any;
  // Copier coller
  public rowCopied;
  public isCopy = false;

  constructor() { }

  getDateHeure(): any {
    return this.selectedDateHeure;
  }

  setDateHeure(date, heure): void {
    this.selectedDateHeure = { date, heure};
  }

  getTache(): any {
    return this.selectedTache;
  }

  setTache(id): void {
    this.selectedTache = id;
  }

  calcDuration(heuredebut, heurefin): number {
    return new Date('2014-03-01 ' + heurefin).getTime() - new Date('2014-03-01 ' + heuredebut).getTime();
  }


}
