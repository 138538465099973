import { Component, AfterViewInit, Input } from '@angular/core';
import { MainService, DataService, ProcService, AttrService, EventService } from '../../../services';

@Component({
  selector: 'popup-1011',
  template: `
    <div class="udo-pop udo-pop-visible" [style.z-index]="_altitude">
      <div class="udo-pop-dialog popup-1011">
        <div *ngIf="headervisible" class="udo-pop-title popup-header-1011">
          Création salarié
          <i (click)="close()" class="udo-pop-close ion-icons ion-close-round" aria-hidden="true"></i>
        </div>
        <div class="udo-pop-content popup-content-1011">
          <div class="udo-r internal-popup-1011">
            <div *ngIf="dataService.isVisible(true, 0, [], null)"  class="zone-container-1012">
  <div class="udo-card udo-card-1012 ">
      <div class="udo-card-inner udo-card-inner-1012">
          <div class="udo-r">
              
        <input-1014 id="1014"  *ngIf="dataService.isVisible(true, 0, [], null)"  [datarow]="_datarow" class="obj-container-1014">
        </input-1014>
    

        <input-1016 id="1016"  *ngIf="dataService.isVisible(true, 0, [], null)"  [datarow]="_datarow" class="obj-container-1016">
        </input-1016>
    

        <input-1018 id="1018"  *ngIf="dataService.isVisible(true, 0, [], null)"  [datarow]="_datarow" class="obj-container-1018">
        </input-1018>
    

       <button-1020 id="1020"  *ngIf="dataService.isVisible(true, 0, [], null)"  class="obj-container-1020">
        </button-1020>
    
          </div>
      </div>
  </div>
</div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [
    '../../../app.component.css',
    './component.css'
  ]
})

export class popup1011 implements AfterViewInit {

  public headervisible = 1;
  public _altitude;
  private _guid;
  public _datarow;

  @Input()
  public set altitude(altitude) {
    this._altitude = altitude;
  }

  @Input()
  public set guid(guid) {
    this._guid = guid;
  }

  constructor(
    public dataService: DataService,
    private mainService: MainService,
    private procService: ProcService,
    public attrService: AttrService,
    public eventService: EventService
  ) {

  }

  public ngAfterViewInit(): void {
    this.initevents();
  }

  async initevents() {

  }

  close(): void {
    this.dataService.deleteObjectVariable(1011);
    this.dataService.removePopup(this._guid);
  }

}
