import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { UtilModule } from '../utils/utils.module';
import { UdoSharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularEditorModule } from '@kolkov/angular-editor';


import { popup1011 } from './popups/popup-1011/popup.component';
import { input1014 } from './inputs/input1014';
import { input1016 } from './inputs/input1016';
import { input1018 } from './inputs/input1018';
import { button1020 } from './buttons/button1020';
import { dropdown1771 } from './popups/dropdown-1771/popup.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DndModule,
    UtilModule,
    UdoSharedModule,
    PdfViewerModule,
    NgxChartsModule,
    RouterModule,
    BrowserAnimationsModule,
    AngularEditorModule
  ],
  declarations: [  
    
    popup1011,
    input1014,
    input1016,
    input1018,
    button1020,
    dropdown1771,
    
  ],
  exports: [
    
    popup1011,
    input1014,
    input1016,
    input1018,
    button1020,
    dropdown1771,
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PopupModule { }
