
let _VERSION = '0.0.0';
let _DEBUG_INFO_ENABLED = true;

let _API_URL = 'https://back.udo.tools/';
let _MQ_URL = 'wss://notif.udo-project.com/ws';
let _MQ_LOGIN = 'admin';
let _MQ_PASSCODE = 'tLdA96x92q63TmQD';
let _URL_CONNECT = 'https://connect.udo.tools';

let _MAPBOX_TOKEN = 'pk.eyJ1IjoidWRvcHJvZCIsImEiOiJjazNvNzZ6cHYxM2xnM3B0NTlhdTR6c2p6In0.7qoOiZGcslxh0P15F1_roA';

let _PROJECT_ID = 181;

let _APP_ID = 635;

let _APP_GUID = '85cbf0f6-1aaa-4b55-9dfd-20ee4097d105';

let _APP_NAME = `TEST`;

let _AUTH_TYPE = 0;

let _AUTH_ID = 0;

export const VERSION = _VERSION;
export const DEBUG_INFO_ENABLED = _DEBUG_INFO_ENABLED;
export const API_URL = _API_URL;
export const MQ_URL = _MQ_URL;
export const MQ_LOGIN = _MQ_LOGIN;
export const MQ_PASSCODE = _MQ_PASSCODE;
export const MAPBOX_TOKEN = _MAPBOX_TOKEN;
export const PROJECT_ID = _PROJECT_ID;
export const APP_ID = _APP_ID;
export const APP_NAME = _APP_NAME;
export const URL_CONNECT = _URL_CONNECT;
export const APP_GUID = _APP_GUID;
export const AUTH_TYPE = _AUTH_TYPE;
export const AUTH_ID = _AUTH_ID;
