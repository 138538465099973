import { ChampIhm } from '.';
import { UserQuery } from './user-query.model';

export class Application {

  public userId: number;
  public id: number;
  public parentId: number;
  public libelle: string;
  public arbre: string;
  public url: string;
  public reference: string;
  public datecreation: string;
  public listChampIhm: Array<ChampIhm> = [];
  public listUserQuery?: Array<UserQuery>;
  public listColonne?: any[] = [];
  public typeapplication?: number;
  public positionnavigation?: string;
  public versionapplication?: string;

  constructor() {
    this.listUserQuery = new Array<UserQuery>();
  }
}
