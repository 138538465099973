import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  
{ path: '', loadChildren: () => import('./pages/page-2243/page.module').then((m) => m.Page2243Module), data: {id: '2243'} },
{ path: 'page2', loadChildren: () => import('./pages/page-2533/page.module').then((m) => m.Page2533Module), data: {id: '2533'} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
