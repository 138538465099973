import { ElementRef, HostListener, Output, EventEmitter, Directive } from '@angular/core';

@Directive({
    selector: '[gantt-dnd]'
  })
  export class GanttDndDirective {
    constructor(public _el: ElementRef) { }
  
    public y: any;
    public x: any;
    public drag = false;
    public mousedown = false;
  
    @Output()
    ondrag: EventEmitter<any> = new EventEmitter();
    @Output()
    ondragstart: EventEmitter<number> = new EventEmitter();
    @Output()
    ondragstop: EventEmitter<number> = new EventEmitter();
  
    @HostListener('document:mousemove', ['$event'])
    mousemove(evt): void {
      if (this.mousedown && this.drag === false) {
        this.drag = true;
        this.ondragstart.emit(1);
      }
      const deltaX = evt.pageX - this.x;
      if (this.drag === true) {
        this.ondrag.emit(deltaX);
      }
    }
  
    @HostListener('document:mouseup', ['$event'])
    mouseup(evt): void {
      this.mousedown = false;
      if (this.drag === true) {
        this.drag = false;
        this.ondragstop.emit(this.x);
      }
    }
  
    @HostListener('mousedown', ['$event'])
    public onmousedown(evt): void {
      evt.preventDefault();
      this.mousedown = true;
      this.x = evt.pageX;
    }
  
  }
  