import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UdoSharedModule, UserRouteAccessService } from './shared';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { PopupModule } from './components/popup.module';
import { OptionsService, DateService, ProcService, EventService, AttrService, MainService, AccService, MapService,
  ItemService, DataService, ApplicationService, PositionService, PrivateService, CalendrierService, CalculationService } from './services';
import { SharedCompModule } from './components/shared/shared-comp.module';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UdoSharedModule,
    FormsModule,
    NgxWebstorageModule.forRoot({ prefix: 'jhi', separator: '-'}),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    PopupModule,
    SharedCompModule
  ],
  providers: [
    UserRouteAccessService,
    OptionsService,
    DateService,
    AccService,
    ItemService,
    DataService,
    ApplicationService,
    PrivateService,
    CalendrierService,
    ProcService,
    MainService,
    AttrService,
    CalculationService,
    MapService,
    EventService,
    PositionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
