import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from '../app.constants';
import { AuthServerProvider } from '../shared';
import { Item, PrivateRow, Pager, PrivateMail, PrivateCell, Fichier } from '../models';

@Injectable()
export class PrivateService {

  private resourceUrl = `${API_URL}api/private`;
  private userUrl = `${API_URL}api`;

  constructor(private http: HttpClient, private authserv: AuthServerProvider) { }

  addPrivateRow(privateRow: PrivateRow): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: PrivateRow = Object.assign({ }, privateRow);
    return this.http.post(`${this.resourceUrl}/add`, copy, { headers: options });
  }

  callFunction(privateRow: PrivateRow): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- callFunction()');
    const copy: PrivateRow = Object.assign({ }, privateRow);
    return this.http.post(`${this.resourceUrl}/function`, copy, { headers: options });
  }

  getPrivateItemRow(privateRow: PrivateRow): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- getPrivateItemRow()');
    const copy: PrivateRow = Object.assign({ }, privateRow);
    return this.http.post(`${this.resourceUrl}/privateitemrow`, copy, { headers: options });
  }

  getPrivateParentRow(privateRow: PrivateRow): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- getPrivateParent()');
    const copy: PrivateRow = Object.assign({ }, privateRow);
    return this.http.post(`${this.resourceUrl}/parentrow`, copy, { headers: options });
  }

  getPrivateChildRow(privateRow: PrivateRow): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- getPrivateChildRow()');
    const copy: PrivateRow = Object.assign({ }, privateRow);
    return this.http.post(`${this.resourceUrl}/childrow`, copy, { headers: options });
  }

  addMultiPrivateRow(listprivateRow: PrivateRow[]): Observable<any> {
    const options = this.authserv.getOptions();
    return this.http.post(`${this.resourceUrl}/multiadd`, this.convertListPrivateRowToRequestBody(listprivateRow), { headers: options });
  }

  addMultiXmlPrivateRow(listprivateRow: PrivateRow[]): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- addMultiXmlPrivateRow()');
    return this.http.post(`${this.resourceUrl}/multixmladd`, this.convertListPrivateRowToRequestBody(listprivateRow), { headers: options });
  }

  findMultiPrivateData(listPager: Array<Pager>): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED findMultiPrivateData--- ()');
    return this.http.post(`${this.resourceUrl}/multidata`, this.convertListPagerToRequestBody(listPager), { headers: options });
  }

  findAllUdoPrivateData(page: Pager): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: Pager = Object.assign({ }, page);
    return this.http.post(`${this.resourceUrl}/data`, copy, { headers: options });
  }

  exportAllUdoPrivateData(page: Pager): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: Pager = Object.assign({ }, page);
    return this.http.post(`${this.resourceUrl}/exportall`, copy, { headers: options, responseType: 'blob'}).pipe(map((res) => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  findAllGraphData(page: Pager): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- findAllGraphData()');
    const copy: Pager = Object.assign({ }, page);
    return this.http.post(`${this.resourceUrl}/datagraph`, copy, { headers: options });
  }

  sendMail(mail: any): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: any = Object.assign({ }, mail);
    return this.http.post(`${this.resourceUrl}/sendmail`, copy, { headers: options });
  }

  deleteMulti(listPrivateRow: Array<PrivateRow>): Observable<boolean> {
    const options = this.authserv.getOptions();
    return this.http.put<boolean>(`${this.resourceUrl}/rows/multi`, this.convertListPrivateRowToRequestBody(listPrivateRow),
    { headers: options });
  }

  generatePdf(generator: any): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: any = Object.assign({ }, generator);
    return this.http.post(`${API_URL}api/champetat/generate`, copy, { headers: options, responseType: 'blob'}).pipe(map((res) => {
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  upload(fileUploaded): Observable<Fichier> {
    const options = this.authserv.getOptions();
    return this.http.post(`${API_URL}api/file/upload`, fileUploaded, { headers: options });
  }

  download(fichier: Fichier): Observable<any> {
    const options = this.authserv.getOptions();
    return this.http.post(`${API_URL}api/file/download`, fichier, { headers: options, responseType: 'blob', observe: 'response' }).pipe(map((res) => {
      return { blob: res.body, name: this.getFileNameFromHttpResponse(res)};
    }));
  }

  mergePdf(listPdf): Observable<any> {
    console.log(listPdf)
    const options = this.authserv.getOptions();
    return this.http.post(`${API_URL}api/champetat/merge`, listPdf, { headers: options, responseType: 'blob'}).pipe(map((res) => {
      console.log(res)
      return new Blob([res], { type: 'application/pdf' });
    }));
  }

  createUser(user): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: any = Object.assign({ }, user);
    return this.http.post(`${this.userUrl}/register/profilestudio`, copy, { headers: options });
  }

  sendXML(xml: any): Observable<any> {
    const options = this.authserv.getOptions();
    console.log('--- UPDATED --- sendXML()');
    return this.http.post(`${this.resourceUrl}/uploadxml`, xml, { headers: options });
  }

  private convertListPagerToRequestBody(listPager: Array<Pager>): any[] {
    const requestBody = [];
    for (const pager of listPager) {
      requestBody.push(pager);
    }
    return requestBody;
  }

  private convertListPrivateRowToRequestBody(listprivateRow: Array<PrivateRow>): any[] {
    const requestBody = [];
    for (const row of listprivateRow) {
      requestBody.push(row);
    }
    return requestBody;
  }

  public jsonToPrivateCell(list): PrivateCell[] {
    const listCell: PrivateCell[] = [];
    for (const champ of list) {
      const cell: PrivateCell = new PrivateCell();
      cell.champref = champ.champ;
      cell.value = champ.value;
      listCell.push(cell);
    }
    return listCell;
  }

  getFileNameFromHttpResponse(httpResponse): string {
    const contentDispositionHeader = httpResponse.headers.get('content-disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

}
