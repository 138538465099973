import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { OptionsService } from '../../services';
import { AccountService } from './account.service';

@Injectable()
export class Principal {
  private userIdentity: any;
  public authenticated = false;
  public currentProjectName: string = null;
  private authenticationState = new Subject<any>();

  constructor(
    private account: AccountService,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService,
    // private navbarService: NavbarService,
    private optionsService: OptionsService
  ) { }

  authenticate(identity): void {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[]): Promise<boolean> {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
      return Promise.resolve(false);
    }
    for (let i = 0; i < authorities.length; i++) {
      if (this.userIdentity.authorities.indexOf(authorities[i]) !== -1) {
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  }

  hasAuthority(authority: string): Promise<boolean> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then((id) => {
      return Promise.resolve(id.authorities && id.authorities.indexOf(authority) !== -1);
    }, () => {
      return Promise.resolve(false);
    });
  }

  identity(force?: boolean): Promise<any> {
    if (force === true) {
      this.userIdentity = undefined;
    }

    // check and see if we have retrieved the userIdentity data from the server.
    // if we have, reuse it by immediately resolving
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    // retrieve the userIdentity data from the server, update the identity object, and then resolve.
    return this.account.get().toPromise().then((account) => {
      if (account) {
        this.setCurrentAccount(account);
      } else {
        this.setCurrentAccount(null);
      }
      this.authenticationState.next(this.userIdentity);
      return this.userIdentity;
    }).catch((err) => {
      this.setCurrentAccount(null);
      this.authenticationState.next(this.userIdentity);
      return err;
    });
  }

  registerProfile(profile: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.account.saveProfile(profile).subscribe((result) => {
        resolve(result);
      }, (err) => {
        reject(err);
      });
    });
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  getIdentity(): any {
    return this.userIdentity;
  }

  setCurrentAccount(result): void {
    this.userIdentity = result;
    this.authenticated = (result !== null && result !== undefined);
    this.$localStorage.store('currentAccount', result);
  }

  getCurrentAccount(): any {
    return this.$localStorage.retrieve('currentAccount');
  }

  setCurrentUser(result): void {
    this.$localStorage.store('currentUser', result);
  }

  setCurrentProfile(profile): void {
    this.$localStorage.store('currentProfile', profile);
  }

  getCurrentUser(): any {
    return this.$localStorage.retrieve('currentUser');
  }

  clearCurrentUser(): void {
    this.$localStorage.clear('currentUser');
  }

  getCurrentUserRole(): string | null {
    const currentUser = this.getCurrentUser();
    if (currentUser !== undefined && currentUser !== null) {
      return this.optionsService.getOption(currentUser.listUtilisateurOptions, 'UserRole');
    } else {
      return null;
    }
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.avatar : null;
  }

  clearSessionAndLocalStorage(): void {
    this.$localStorage.clear();
    this.$sessionStorage.clear();
  }
}
