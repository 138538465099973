import { Component, ElementRef, ViewChild, HostListener, OnInit, Input } from '@angular/core';
import { MainService, DataService, ProcService, AttrService, EventService } from '../../../services';
import { animate, style, transition, trigger } from '@angular/animations';
import { isNumber } from 'util';

@Component({
  selector: 'dropdown-1771',
  template: `
  <div #menu [@enterAnimation] [class.has-puce]="ispuce" class="udo-p-dropdown dropdown-1771" [style.left.px]="x" [style.top.px]="y" [style.z-index]="_altitude">
    <div class="udo-p-drop-inner dropdown-inner-1771">
      <div class="udo-p-puce dropdown-inner-puce-1771" *ngIf="ispuce"></div>
      <div class="udo-r internal-popup-1771">
        
      </div>
    </div>
  </div>
  `,
  styleUrls: [
    '../../../app.component.css',
    './component.css'
  ],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translate(0, .5em)', opacity: '0'}),
        animate('300ms ease-out', style({ transform: 'none', opacity: '1'}))
      ])
    ]),
  ]
})

export class dropdown1771 implements OnInit {

  public x = 0;
  public y = 0;
  public idpere;
  public _altitude;
  private _guid;
  public positionX: number = 1;
  public positionY: number = 1;
  public ispuce: boolean = true;
  public _datarow;

  @ViewChild('menu', { static: true }) element: ElementRef;

  @Input()
  public set altitude(altitude) {
    this._altitude = altitude;
  }

  @Input()
  public set guid(guid) {
    this._guid = guid;
  }

  @Input()
  public set param(param: any) {
    this.idpere = param[0];
  }

  @HostListener('document:mousedown', ['$event'])
  clickedOutside($event): void {
    this.onMouseDown($event);
  }

  constructor(
    public dataService: DataService,
    private mainService: MainService,
    private procService: ProcService,
    public attrService: AttrService,
    public eventService: EventService
  ) {

  }

  public ngOnInit(): void {
    this.setPosition();
  }

  public ngAfterViewInit(): void {
    this.initevents();
  }

  async initevents() {

  }

  setPosition(): void {
    let objId = this.idpere;
    if (isNumber(this.idpere)) {
      objId = 'c_' + this.idpere;
    }
    const data = this.dataService.getElementSize(objId);
    // Left
    if (this.positionX === 0) {
      this.x = data.left - 300.0;
    // Middle
    } else if (this.positionX === 1) {
      this.x = data.left - 300.0 / 2 + data.width / 2;
    // Right
    } else {
      this.x = data.left + data.width;
    }
    // Top
    if (this.positionY === 0) {
      this.y = data.top - 300.0;
    // Bottom
    } else if (this.positionY === 1) {
      this.y = data.top + data.height;
    // Middle
    } else {
      this.y = data.top - 300.0 / 2 + data.height / 2;
    }
  }

  onMouseDown(event: any): void {
    if (this.element !== undefined
     && !this.isDescendant(this.element.nativeElement, event.target)
     && event.target !== this.element.nativeElement) {
      this.dataService.removePopup(this._guid);
      this.dataService.deleteObjectVariable(1771);
    }
  }

  isDescendant(parent: any, child: any): boolean {
    let node: any = child.parentNode;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
}
