import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from '../app.constants';
import { AuthServerProvider } from '../shared';
import { AppInit } from '../models/app-init.model';

@Injectable()
export class ApplicationService {

  private resourceUrl = `${API_URL}api/application`;

  constructor(private http: HttpClient, private authserv: AuthServerProvider) { }

  initApp(appInit: AppInit): Observable<any> {
    const options = this.authserv.getOptions();
    const copy: AppInit = Object.assign({ }, appInit);
    return this.http.post(`${this.resourceUrl}/initprod`, copy, { headers: options });
  }

  findOneUdoAdmin(id: number): Observable<any> {
    const options = this.authserv.getOptions();
    return this.http.get(`${this.resourceUrl}/admin/${id}`, { headers: options });
  }

}
