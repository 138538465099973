import { Injectable } from '@angular/core';
import { ReturnType } from '../utils/calculation/return-type.enum';
import {
  quotations, operators, roundIfNeeded, isTruthy, removeQuotesIfNeeded, explicitDouble
} from '../utils/calculation/calculation.utils';
import { CalculationExpression } from '../utils/calculation/calculationExpression.model';
import { CalculationFunctionHandlers } from '../utils/calculation/calculation-function-handlers';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor() { }

  private isUnary(expression: string, index: number): boolean {
    const leftExpression = expression.substring(0, index).trim();
    if (index > 0 && expression[index - 1].toLowerCase() === 'e') {
      return true;
    }
    if (leftExpression.length === 0) {
      return true;
    }
    for (const operator of operators) {
      if (leftExpression.length >= operator.length && leftExpression.substring(leftExpression.length - operator.length) === operator) {
        return true;
      }
    }
    return false;
  }

  private handleOperators(expression: string, operatorList: string[], checkUnary = false): any {
    let inQuote = false;
    let currentOperator: string;
    let currentIndex = -1;
    let currentQuotation: string;
    operatorList.sort((a, b) => b.length - a.length);
    for (let i = 0; i < expression.length; i++) {
      const index = i;
      let isOperatorHere = false;
      let operatorHere: string;
      for (const operator of operatorList) {
        if (expression.substring(index).length >= operator.length && expression.substring(index, index + operator.length) === operator) {
          isOperatorHere = true;
          operatorHere = operator;
          break;
        }
      }
      if (!inQuote && isOperatorHere && (!checkUnary || !this.isUnary(expression, index))) {
        currentIndex = index;
        currentOperator = operatorHere;
        i += operatorHere.length - 1;
      } else if (expression[index] === '"' || expression[index] === '\'') {
        if (inQuote && expression[index] === currentQuotation) {
          inQuote = false;
        } else if (!inQuote) {
          inQuote = true;
          currentQuotation = expression[index];
        }
      }
    }

    return { currentIndex, operator: currentOperator };
  }

  private handleParenthesis(expression: string, precision: number): string {
    let parenthesisFound = false;
    let parenthesisLevel = 0;
    let parenthesisOpenIndex = 0;
    let currentQuotation: string;
    let inQuote = false;

    for (let i = 0; i < expression.length; i++) {
      if (inQuote) {
        if (expression[i] === currentQuotation) {
          inQuote = false;
        }
      } else {
        if (quotations.indexOf(expression[i]) > -1) {
          currentQuotation = expression[i];
          inQuote = true;
        } else if (expression[i] === '(') {
          parenthesisLevel++;
          if (!parenthesisFound) {
            parenthesisOpenIndex = i;
            parenthesisFound = true;
          }
        } else if (expression[i] === ')') {
          parenthesisLevel--;
          if (parenthesisLevel === 0) {
            // Number of closed parenthesis matches open parenthesis => we have the whole expression
            const computedExpression = expression.substring(0, parenthesisOpenIndex) +
              this.evaluate(expression.substring(parenthesisOpenIndex + 1, i), precision).value +
              expression.substring(i + 1);
            return this.handleParenthesis(computedExpression, precision);
          }
        }
      }
    }

    if (!parenthesisFound) {
      return expression;
    } else {
      throw new SyntaxError('Unmatched parenthesis');
    }
  }

  evaluate(expression: string, precision: number, type: ReturnType = ReturnType.DOUBLE, round = false): CalculationExpression {
    const handlers = new CalculationFunctionHandlers(this, new DatePipe('en-US'));
    let workExpr = expression.trim();
    let currentIndex = -1;
    let operator: string = null;

    // Handle functions
    workExpr = handlers.handleFunctions(workExpr, precision);

    // Handle parenthesis
    workExpr = this.handleParenthesis(workExpr, precision);

    // Handle ||
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkOr = this.handleOperators(workExpr, ['||']);
      currentIndex = checkOr.currentIndex;
      operator = checkOr.operator;
    }

    // Handle &&
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkAnd = this.handleOperators(workExpr, ['&&']);
      currentIndex = checkAnd.currentIndex;
      operator = checkAnd.operator;
    }

    // Handle equalities
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkEqualities = this.handleOperators(workExpr, ['==', '!=']);
      currentIndex = checkEqualities.currentIndex;
      operator = checkEqualities.operator;
    }

    // Handle comparators
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkComparators = this.handleOperators(workExpr, ['>=', '<=', '<', '>']);
      currentIndex = checkComparators.currentIndex;
      operator = checkComparators.operator;
    }

    // Handle low priority operators
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkLowPriority = this.handleOperators(workExpr, ['+', '-'], true);
      currentIndex = checkLowPriority.currentIndex;
      operator = checkLowPriority.operator;
    }

    // Handle high priority operators
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkHighPriority = this.handleOperators(workExpr, ['*', '/', '%']);
      currentIndex = checkHighPriority.currentIndex;
      operator = checkHighPriority.operator;
    }

    // Handle not
    if (currentIndex < 0 && type !== ReturnType.STRING) {
      const checkNot = this.handleOperators(workExpr, ['!']);
      currentIndex = checkNot.currentIndex;
      operator = checkNot.operator;
    }

    // return etc…
    if (operator && currentIndex > -1) {
      const exp1 = workExpr.substring(0, currentIndex);
      const ev1 = this.evaluate(exp1, precision, type);
      const exp2 = workExpr.substring(currentIndex + operator.length);
      const ev2 = this.evaluate(exp2, precision, type);
      let chosenType = type;
      if (chosenType === ReturnType.ANY) {
        if (ev1.type === ReturnType.INT && ev2.type === ReturnType.INT) {
          chosenType = ReturnType.INT;
        } else if ((ev1.type === ReturnType.INT || ev1.type === ReturnType.DOUBLE) &&
          (ev2.type === ReturnType.INT || ev2.type === ReturnType.DOUBLE)) {
          chosenType = ReturnType.DOUBLE;
        } else {
          if (ev1.type === ReturnType.DOUBLE) {
            ev1.value = explicitDouble(roundIfNeeded(Number(ev1.value), true, precision).toString());
          }
          if (ev2.type === ReturnType.DOUBLE) {
            ev2.value = explicitDouble(roundIfNeeded(Number(ev2.value), true, precision).toString());
          }
          chosenType = ReturnType.STRING;
        }
      }
      let value: string;
      switch (operator) {
        case '-':
          if (chosenType === ReturnType.INT) {
            value = (Number(ev1.value) - Number(ev2.value)).toString();
          } else if (chosenType === ReturnType.DOUBLE) {
            value = explicitDouble(roundIfNeeded(Number(ev1.value) - Number(ev2.value), round, precision).toString());
          } else {
            throw new SyntaxError('Trying to perform substraction on non numerical terms');
          }
          return new CalculationExpression(value, chosenType);
        case '+':
          switch (chosenType) {
            case ReturnType.INT:
              value = (Number(ev1.value) + Number(ev2.value)).toString();
              break;
            case ReturnType.DOUBLE:
              value = explicitDouble(roundIfNeeded(Number(ev1.value) + Number(ev2.value), round, precision).toString());
              break;
            case ReturnType.STRING:
              value = ev1.value + ev2.value;
              break;
            default:
              throw new SyntaxError('Wrong terms for addition');
          }
          return new CalculationExpression(value, chosenType);

        case '*':
          switch (chosenType) {
            case ReturnType.INT:
              value = (Number(ev1.value) * Number(ev2.value)).toString();
              break;
            case ReturnType.DOUBLE:
              value = explicitDouble(roundIfNeeded(Number(ev1.value) * Number(ev2.value), round, precision).toString());
              break;
            default:
              throw new SyntaxError('Trying to perform multiplication on non numerical terms');
          }
          return new CalculationExpression(value, chosenType);

        case '/':
          switch (chosenType) {
            case ReturnType.INT:
              value = Math.trunc(Number(ev1.value) / Number(ev2.value)).toString();
              break;
            case ReturnType.DOUBLE:
              value = explicitDouble(roundIfNeeded(Number(ev1.value) / Number(ev2.value), round, precision).toString());
              break;
            default:
              throw new SyntaxError('Trying to perform division on non numerical terms');
          }
          return new CalculationExpression(value, chosenType);

        case '%':
          switch (chosenType) {
            case ReturnType.INT:
              value = (Number(ev1.value) % Number(ev2.value)).toString();
              break;
            case ReturnType.DOUBLE:
              value = explicitDouble(roundIfNeeded(Number(ev1.value) % Number(ev2.value), round, precision).toString());
              break;
            default:
              throw new SyntaxError('Trying to perform substraction on non numerical terms');
          }
          return new CalculationExpression(value, chosenType);

        case '<':
          return new CalculationExpression(handlers.compareExpressions('lt(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '>':
          return new CalculationExpression(handlers.compareExpressions('gt(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '<=':
          return new CalculationExpression(handlers.compareExpressions('le(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '>=':
          return new CalculationExpression(handlers.compareExpressions('ge(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '==':
          return new CalculationExpression(handlers.compareExpressions('eq(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '!=':
          return new CalculationExpression(handlers.compareExpressions('ne(', ev1, ev2).toString(), ReturnType.BOOLEAN);

        case '&&':
          return new CalculationExpression((isTruthy(ev1) && isTruthy(ev2)).toString(), ReturnType.BOOLEAN);

        case '||':
          return new CalculationExpression((isTruthy(ev1) || isTruthy(ev2)).toString(), ReturnType.BOOLEAN);

        case '!':
          if (ev1.value) {
            throw new SyntaxError();
          } else {
            return new CalculationExpression((!isTruthy(ev2)).toString(), ReturnType.BOOLEAN);
          }

        default:
          throw new SyntaxError('Unknown operator');
      }
    } else {
      switch (type) {
        case ReturnType.INT:
          if (Number.isNaN(Number(workExpr))) {
            throw new SyntaxError('Not a number');
          } else {
            return new CalculationExpression(Math.round(Number(workExpr)).toString(), type);
          }

        case ReturnType.DOUBLE:
          if (Number.isNaN(Number(workExpr))) {
            throw new SyntaxError('Not a number');
          } else {
            return new CalculationExpression(roundIfNeeded(Number(workExpr), round, precision).toString(), type);
          }

        case ReturnType.STRING:
          return new CalculationExpression(removeQuotesIfNeeded(workExpr), type);

        default:
          if (Number.isNaN(Number(workExpr))) {
            return new CalculationExpression(removeQuotesIfNeeded(workExpr), ReturnType.STRING);
          } else if (Number.isInteger(Number(workExpr)) && workExpr.indexOf('.') === -1) {
            return new CalculationExpression(Number(workExpr).toString(), ReturnType.INT);
          } else {
            return new CalculationExpression(
              explicitDouble(roundIfNeeded(Number(workExpr), round, precision).toString()),
              ReturnType.DOUBLE);
          }
      }
    }
  }

  public evaluation(expression: string, precision: number): string {
    return this.evaluate(expression, precision, ReturnType.ANY, true).value;
  }
}
