import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services';

export class ObjetData implements OnInit, OnDestroy {

  public id;
  public _link: any;
  public _datarow: any;
  private eventSubscription: Subscription;

  constructor(
    public dataService: DataService
  ) { }

  public ngOnInit(): void {
    this.eventSubscription = this.dataService.notifyevent$.subscribe((res) => {
      if (res.id === this.id) {
        if (res.type === 'affecte') {
          if (res.data !== null) {
            if (res.index === undefined) {
              this.updateValue(res.data);
            } else {
              if (this._link !== undefined && +this._link[1] === +res.index) {
                this.updateValue(res.data);
              }
            }
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.eventSubscription !== undefined) {
      this.eventSubscription.unsubscribe();
    }
  }

  public updateValue(value): void { }

  public init(): void { }



}
