// import { UserAction } from './user-action.model';

export class PrivateMail {
    constructor(
        public body?: string,
        public subject?: string,
        public to?: string[],
        public attachments?: string[],
        // public useraction?: UserAction,
    ) {
        this.to = [];
        this.attachments = [];
    }
}
