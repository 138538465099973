import { Component, Input, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataService, ProcService, MainService, AttrService } from '../../services';
import { ObjetData } from '../objet-data.component';

@Component({
  selector: 'button-1020',
  template: `
  <div [attr.id]="getId()"  class="obj-inner-1020">
    <button (click)="click()" class="udo-btn btn-1020" type="button">
      <i *ngIf="typelibelle !== 0" class="ion-icons " aria-hidden="true"></i>
      <span *ngIf="typelibelle !== 1">
        VALIDER
      </span>
    </button>
  </div>
  `,
  styleUrls: [
    '../../app.component.css',
    '../../components/popups/popup-1011/component.css'
  ]
})

export class button1020 extends ObjetData implements OnInit, OnDestroy, AfterViewInit {

  public typelibelle = 0;
  public idmenuassocie = 0;
  public menumapping(): any[] {
    const param_zr_0 = (this._link !== undefined ? this._link[0] : null);
    const list = [

    ];
    return list;
  }

  constructor(
    public dataService: DataService,
    private procService: ProcService,
    private mainService: MainService,
    public attrService: AttrService
  ) {
    super(dataService);
  }

  @Input()
  public set link(link: any) {
    this._link = link;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public ngAfterViewInit(): void {
    this.initevents();
  }

  async initevents() {

  }

  async click() {
    if (this.idmenuassocie !== 0) {
      this.dataService.setObjectVariable(this.menumapping(), this.idmenuassocie);
      let idpere = '';
      if (this._link !== undefined && this._link !== null) {
        idpere = `1020@${this._link[1]}`;
      } else {
        idpere = '1020';
      }
      this.dataService.setMenu(this.idmenuassocie, idpere);
    } else {
      const param_zr_0 = (this._link !== undefined ? this._link[0] : null);
      const param_zr_1 = (this._link !== undefined ? this._link[1] : null);
      let param_1022_0;
      const listRows1022 = [];
      const row1022 = {item:this.dataService.getTable(982), libelle: this.dataService.getTable(982).libelle, parentId: 0, listPrivateCell: [{champref: 'pf_2', type: 'TEXT', value: this.dataService.findObjValue(1014, 0, )},
      {champref: 'pf_3', type: 'TEXT', value: this.dataService.findObjValue(1016, 0, )},
      {champref: 'pf_4', type: 'INTEGER', value: this.dataService.findObjValue(1018, 0, )}]};
      for (let i = 0; i < 1; i++) {
        listRows1022.push(row1022);
      }
      try {
        param_1022_0 = await this.mainService.addMultiPrivateRow(listRows1022).toPromise();
        for (const pop of this.dataService.listPopup) {
          if (pop.type === '1011') {
            this.dataService.removePopup(pop.id);
          }
        }
        this.dataService.newevent({id: 995, type: 'exeaction', data: 1});
    
      } catch(err) {
    
      }
    }
  }

  public getId(): any {
    if (this._link === undefined || this._link === null) {
      return `c_1020`;
    } else {
      return `c_1020@${this._link[1]}`;
    }
  }

}
