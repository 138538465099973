export class Sort {
  constructor(
   public id?: number,
   public ordre?: number,
   public parentId?: number,
   public libelle?: string,
   public idchampnatif?: number,
   public typesort?: number,
  ) {
  }
}
