import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Principal, UserRouteAccessService } from './shared';
import { LoginService } from './shared/login/login.service';
import { PROJECT_ID, APP_ID, APP_NAME, URL_CONNECT, APP_GUID, AUTH_TYPE } from './app.constants';
import { AccService, DataService, ApplicationService, ItemService, MainService } from './services';
import { Utilisateur, Item, AppInit, UserQuery, TypeChampEnum, ChampNatif } from './models';
import { NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  account: any;
  public isapploading = true;
  public username: any = ''
  public password: any = ''
  public rememberMe: any;
  public isloginerror = false;
  public appname = '';
  public connecturl = '';
  public appguid = '';
  public creation_key = null;

  constructor(
    public accService: AccService,
    public principal: Principal,
    private loginService: LoginService,
    private appService: ApplicationService,
    public dataService: DataService,
    private itemService: ItemService,
    public mainService: MainService,
    public router: Router,
    private http: HttpClient
   ) { }

  ngOnInit(): void {
    this.appname = APP_NAME;
    this.connecturl = URL_CONNECT;
    this.appguid = APP_GUID;
    this.registerAuthenticationSuccess();
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
      } else if (event instanceof RouteConfigLoadEnd ||
        event instanceof NavigationCancel) {
      } else if (event instanceof NavigationEnd) {
        this.dataService.removePropertyWindow();
      }
    });
  }

  login() {
    this.loginService.login({
        username: this.username,
        password: this.password,
        rememberMe: this.rememberMe
    }).then((data) => {
      this.isloginerror = false;
      this.registerAuthenticationSuccess();
    }).catch((e) => {
      console.log(e)
      this.isloginerror = true;
    });
  }

  registerAuthenticationSuccess() {
    this.isapploading = true;
    this.principal.identity().then((account) => {
        this.account = account;
        if (this.isAuthenticated()) {
          this.getAccountUDo();
        } else {
          if (account.error !== undefined && account.error.toString().indexOf('creation_key') >= 0) {
            this.creation_key = account.error.split("=")[1];
          } else {
            this.creation_key = null;
          }
          this.isapploading = false;
        }       
    });
  }

  getAccountUDo(): void {
    this.principal.identity().then((account) => {
      if (account === null || account === undefined) { // Le compte n'existe pas, il faut le creer sur UDo avec le profil
      } else { // Le compte existe sur UDo
        this.account = account;
        this.authenticationSuccess(account);
      }
    });
  }

  authenticationSuccess(account): void {
    this.setProject(account);
  }

  setProject(account): void {
    if (AUTH_TYPE === 0) {
      this.accService.setProject(PROJECT_ID).subscribe((res: Utilisateur) => {
        this.principal.setCurrentUser(res);
        this.initProperties();
      }, (e) => {
        this.isapploading = false;
      });
    } else {
      this.principal.setCurrentUser({id: account.id, idproject: PROJECT_ID, listUtilisateurOptions: [
        {optionIdentifier: "UserLogin", optionValue: account.login},
        {optionIdentifier: "UserActive", optionValue: "1"},
        {optionIdentifier: "UserRole", optionValue: "admin"},
        {optionIdentifier: "UserNom", optionValue: account.lastName},
        {optionIdentifier: "UserPrenom", optionValue: account.firstName}
      ]});
      this.initProperties();
    }
  }

  isAuthenticated(): boolean {
    return this.principal.authenticated;
  }

  public getJSON(): Observable<any> {
    return this.http.get("assets/json/items.json");
  }

  changePassword() {
    window.open(`${this.connecturl}/setpassword?key=${this.creation_key}`);
  }
  
  initProperties() {
    const currentUser = this.principal.getCurrentUser();
    this.getJSON().subscribe(items => {
      this.itemService.findAllGroup(currentUser.id).subscribe((groups: any[]) => {
        this.dataService.listGroup = groups;
        this.dataService.listTable = items.data;
        this.initevent();
      }, () => {
        this.isapploading = false;
      });
    });    
  }

  async initevent() {
    
    this.isapploading = false;
  }

  getOption(options: any, identifier: string): string {
    let option: any;
    if (undefined === options) {
      return '';
    }
    option = options.find((f) => f.optionIdentifier === identifier);
    if (undefined === option) {
      return '';
    }
    return option.optionValue;
  }

  trackById(index, item): any {
    if (!item) { return; }
    return item.id ? item.id : index;
  }
}
