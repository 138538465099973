import { ChampIhm } from '.';

export class UserQuery {
    constructor(
        public id?: number,
        public query?: string,
        public ordre?: number,
        public listChampIhm?: Array<ChampIhm>,
        public parentId?: number,
        public libelle?: string,
        public data?: any,

    ) {
        this.listChampIhm = [];
    }
}
