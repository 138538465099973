
export class ChampNatif {
  constructor(
    public id?: number,
    public ordre?: number,
    public parentId?: number,
    public libelle?: string,
    public reference?: string,
    public type?: string,
    public obligatoire?: boolean,
    public visible?: boolean,
    public idTable?: number,
    public idChamp?: number,
    public referenceTable?: string,
    public referenceChamp?: string,
  ) {
  }
}
