import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthServerProvider } from '../shared';
import { Utilisateur } from '../models';
import { API_URL } from '../app.constants';

@Injectable()
export class AccService {

  private resourceUrl = `${API_URL}api`;

  constructor(private http: HttpClient, private authserv: AuthServerProvider) { }

  setProject(id): Observable<Utilisateur> {
    const options = this.authserv.getOptions();
    return this.http.get<Utilisateur>(`${this.resourceUrl}/project/user/${id}`, { headers: options, observe: 'response' }).pipe(map((res) => {
      return res.body;
    }));
  }

}
