import { Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DataService, ProcService, MainService, AttrService } from '../../services';
import { ObjetData } from '../objet-data.component';

@Component({
  selector: 'input-1016',
  template: `
  <div id="c_1016"  class="obj-inner-1016">
    <angular-editor *ngIf="type === 11" class="udo-html-editor" [(ngModel)]="value" id="htmledit1016" name="htmleditor1016" (blur)="blur()" ngDefaultControl></angular-editor>
    <ng-container *ngIf="type !== 11">
      <input #input *ngIf="type !== 1" type="text"
      [readonly]="ensaisie === 0"
      
      (keyup.enter)="enter()"
      (blur)="blur()"
      [(ngModel)]="value"
      placeholder="{{intitule}}"
      class="udo-input obj-input"/>
      <textarea *ngIf="type === 1"
      [(ngModel)]="value"
      [readonly]="ensaisie === 0"
      
      (blur)="blur()"
      placeholder="{{intitule}}"
      class="udo-input obj-input"></textarea>
    </ng-container>
  </div>
  `,
  styleUrls: [
    '../../app.component.css',
    '../../components/popups/popup-1011/component.css'
  ]
})

export class input1016 extends ObjetData implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('input', { static: false }) inputElement: ElementRef;

  public value: any = '';
  public intitule: string = 'PRENOM'
  public type: number = 0;
  public refchamp = '';
  public typeassociation = 0;
  public ensaisie = 1;

  constructor(
    public dataService: DataService,
    private procService: ProcService,
    private mainService: MainService,
    public renderer: Renderer2,
    public attrService: AttrService,
    private ref: ChangeDetectorRef
  ) {
    super(dataService)
  }

  @Input()
  public set link(link: any) {
    this._link = link;
  }

  @Input()
  public set datarow(datarow: any) {
    this._datarow = datarow;
    this.getValue();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.id = 1016;
    this.getValue();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataService.deleteObjValue(1016);
  }

  public ngAfterViewInit(): void {
    this.initevents();
  }

  async initevents() {

  }

  public updateValue(value): void {
    this.value = value;
    this.ref.detectChanges();
    this.save();
  }

  public save(): void {
    this.setValue();
    if (this.typeassociation === 1 || this.typeassociation === 3) {
      this.dataService.setObjetValue(this.value, this.refchamp, this._link, this._datarow);
    }
  }

  private getValue(): void {
    if ((this.typeassociation === 1 || this.typeassociation === 2) && this.refchamp !== null) {
      const value = this.dataService.getObjetValue(this.refchamp, this._link, this._datarow);
      if (value !== null && value !== undefined) {
        this.value = value;
      }
    }
    this.setValue();
  }

  private setValue(): void {
    this.dataService.updateObjValue(1016, this.value, 0, (this._link !== undefined ? this._link[1] : ''));    
  }

  public enter(): void {
    this.renderer.selectRootElement(this.inputElement.nativeElement).blur();
  }

  public blur(): void {
    this.save();
    this.blurevents();
  }

  async blurevents() {
    const param_0_0 = this.value;
    const param_zr_0 = (this._link !== undefined ? this._link[0] : null);
    const param_zr_1 = (this._link !== undefined ? this._link[1] : null);

  }

}
